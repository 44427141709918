import React from "react";

const SearchBar = (props) => {
  return (
    <svg
      {...props}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6445 21L15.6445 15M17.6445 10C17.6445 10.9193 17.4635 11.8295 17.1117 12.6788C16.7599 13.5281 16.2443 14.2997 15.5943 14.9497C14.9443 15.5998 14.1726 16.1154 13.3233 16.4672C12.474 16.8189 11.5638 17 10.6445 17C9.72528 17 8.81503 16.8189 7.96575 16.4672C7.11647 16.1154 6.34479 15.5998 5.69478 14.9497C5.04477 14.2997 4.52916 13.5281 4.17737 12.6788C3.82559 11.8295 3.64453 10.9193 3.64453 10C3.64453 8.14348 4.38203 6.36301 5.69478 5.05025C7.00754 3.7375 8.78802 3 10.6445 3C12.501 3 14.2815 3.7375 15.5943 5.05025C16.907 6.36301 17.6445 8.14348 17.6445 10Z"
        stroke="#808080"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchBar;
