import React from "react";
import style from "./index.module.css";

function Footer() {
  return (
    <footer className={style.footer}>
      <svg
        width="62"
        height="61"
        viewBox="0 0 62 61"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={style.logo}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.1592 0H37.2316V5.07684C43.7285 6.53205 49.3086 10.4141 52.9705 15.7215L58.8422 14.1302L62 25.7824L57.3372 27.046C57.4867 28.1755 57.5639 29.3279 57.5639 30.4982C57.5639 34.7461 56.5472 38.7565 54.7439 42.2994L60.1475 47.6722L51.6354 56.2331L46.8983 51.5229C44.0588 53.6043 40.7821 55.1244 37.2316 55.9196V60.9978H25.1592V55.769C21.9669 54.969 19.0083 53.5799 16.4055 51.724L9.85046 55.5085L3.81424 45.0535L8.35093 42.4342C6.50447 38.8584 5.46176 34.8001 5.46176 30.4982C5.46176 29.218 5.55411 27.9593 5.73252 26.7285L0 23.4188L6.03622 12.9638L10.2734 15.4101C13.8146 10.4342 19.0605 6.75595 25.1592 5.22748V0Z"
          fill="url(#paint0_linear_143_1127)"
        />
        <rect
          x="13.9788"
          y="28.4166"
          width="33.4447"
          height="8.75412"
          fill="white"
        />
        <rect
          x="18.0015"
          y="24.1455"
          width="7.81489"
          height="6.69958"
          fill="white"
        />
        <rect
          x="35.3098"
          y="24.1458"
          width="7.81489"
          height="6.69958"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_143_1127"
            x1="21.0292"
            y1="19.6972"
            x2="47.7156"
            y2="50.8314"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9D50F6" />
            <stop offset="1" stopColor="#00D1AB" />
          </linearGradient>
        </defs>
      </svg>
      <div className={style.grid}>
        <p className={style.talk}>
          Lego-build is a command-line application that makes development fun
          again by automating routine tasks. It is a flexible tool that improves
          performance and significantly reduces the time required to ship a web
          application.
        </p>
        <div className={style.contact}>
          <h3>Find Us</h3>
          <p>Connect with us on Twitter and Github!</p>
          <div className={style.icons}>
            <a
              href="https://twitter.com/npmlegobuild"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.723 1.96533C8.12558 1.96533 1.96533 8.12558 1.96533 15.723C1.96533 23.3205 8.12558 29.4807 15.723 29.4807C23.3205 29.4807 29.4807 23.3205 29.4807 15.723C29.4807 8.12558 23.3205 1.96533 15.723 1.96533ZM22.3347 12.3358C22.3439 12.4801 22.3439 12.6306 22.3439 12.778C22.3439 17.2861 18.9106 22.479 12.6368 22.479C10.7021 22.479 8.90867 21.9171 7.39778 20.9497C7.67416 20.9804 7.93826 20.9927 8.22078 20.9927C9.81766 20.9927 11.2856 20.4522 12.4556 19.5371C10.957 19.5064 9.69789 18.5237 9.26796 17.1725C9.79309 17.2493 10.266 17.2493 10.8065 17.1111C10.0349 16.9543 9.34128 16.5352 8.84361 15.925C8.34595 15.3148 8.07487 14.5512 8.07645 13.7638V13.7208C8.52787 13.9757 9.05914 14.1323 9.61498 14.1538C9.14771 13.8424 8.76451 13.4205 8.49935 12.9255C8.23418 12.4305 8.09525 11.8778 8.09487 11.3163C8.09487 10.6806 8.2607 10.1002 8.55858 9.59655C9.41507 10.6509 10.4838 11.5132 11.6954 12.1275C12.907 12.7417 14.2343 13.0942 15.591 13.1619C15.1088 10.8433 16.8408 8.96701 18.9229 8.96701C19.9056 8.96701 20.79 9.37852 21.4134 10.0418C22.1842 9.8975 22.9212 9.60883 23.5784 9.2219C23.3235 10.0111 22.7892 10.6775 22.0798 11.0982C22.7677 11.0245 23.431 10.8341 24.0452 10.567C23.5815 11.2487 23.0011 11.8537 22.3347 12.3358Z"
                  fill="#4D4D4D"
                />
              </svg>
            </a>
            <a
              href="https://github.com/lego-build"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                width="27"
                height="28"
                viewBox="0 0 27 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_143_1148)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.5229 0.246338C6.07694 0.246338 0.0460205 6.27726 0.0460205 13.7233C0.0460205 19.6868 3.90379 24.7238 9.26087 26.5095C9.93471 26.6274 10.1874 26.2231 10.1874 25.8693C10.1874 25.5493 10.1706 24.488 10.1706 23.3593C6.78448 23.9826 5.90848 22.5338 5.63894 21.7757C5.48733 21.3883 4.83033 20.1922 4.25756 19.8721C3.78587 19.6194 3.11202 18.9961 4.24071 18.9793C5.30202 18.9624 6.0601 19.9563 6.31279 20.3606C7.52571 22.399 9.46302 21.8263 10.2379 21.4725C10.3559 20.5965 10.7096 20.0069 11.0971 19.67C8.09848 19.333 4.9651 18.1706 4.9651 13.0157C4.9651 11.5501 5.48733 10.3372 6.34648 9.3938C6.21171 9.05688 5.74002 7.67549 6.48125 5.82241C6.48125 5.82241 7.60994 5.46865 10.1874 7.2038C11.2656 6.90057 12.4111 6.74895 13.5566 6.74895C14.7022 6.74895 15.8477 6.90057 16.9259 7.2038C19.5033 5.4518 20.632 5.82241 20.632 5.82241C21.3732 7.67549 20.9016 9.05688 20.7668 9.3938C21.6259 10.3372 22.1482 11.5333 22.1482 13.0157C22.1482 18.1875 18.9979 19.333 15.9993 19.67C16.4879 20.0911 16.909 20.8997 16.909 22.1632C16.909 23.9657 16.8922 25.4145 16.8922 25.8693C16.8922 26.2231 17.1449 26.6443 17.8187 26.5095C20.4941 25.6062 22.8188 23.8868 24.4658 21.5931C26.1128 19.2994 26.9991 16.547 26.9999 13.7233C26.9999 6.27726 20.9689 0.246338 13.5229 0.246338Z"
                    fill="#4D4D4D"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_143_1148">
                    <rect
                      width="26.9538"
                      height="26.9538"
                      fill="white"
                      transform="translate(0.0460205 0.246338)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <p className={style.bottom}>Copyright &copy; 2022. The O.G. Team.</p>
    </footer>
  );
}

export default Footer;
