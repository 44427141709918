import React from "react";
import style from "./index.module.css";

function GithubButton() {
  return (
    <a
      className={style.button}
      href="https://github.com/lego-build/lego-build"
      target={"_blank"}
      rel="noreferrer"
    >
      {" "}
      <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1275_1201)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 0.223999C4.0275 0.223999 0 4.2515 0 9.224C0 13.2065 2.57625 16.5702 6.15375 17.7627C6.60375 17.8415 6.7725 17.5715 6.7725 17.3352C6.7725 17.1215 6.76125 16.4127 6.76125 15.659C4.5 16.0752 3.915 15.1077 3.735 14.6015C3.63375 14.3427 3.195 13.544 2.8125 13.3302C2.4975 13.1615 2.0475 12.7452 2.80125 12.734C3.51 12.7227 4.01625 13.3865 4.185 13.6565C4.995 15.0177 6.28875 14.6352 6.80625 14.399C6.885 13.814 7.12125 13.4202 7.38 13.1952C5.3775 12.9702 3.285 12.194 3.285 8.7515C3.285 7.77275 3.63375 6.96275 4.2075 6.33275C4.1175 6.10775 3.8025 5.18525 4.2975 3.94775C4.2975 3.94775 5.05125 3.7115 6.7725 4.87025C7.4925 4.66775 8.2575 4.5665 9.0225 4.5665C9.7875 4.5665 10.5525 4.66775 11.2725 4.87025C12.9937 3.70025 13.7475 3.94775 13.7475 3.94775C14.2425 5.18525 13.9275 6.10775 13.8375 6.33275C14.4113 6.96275 14.76 7.7615 14.76 8.7515C14.76 12.2052 12.6562 12.9702 10.6537 13.1952C10.98 13.4765 11.2613 14.0165 11.2613 14.8602C11.2613 16.064 11.25 17.0315 11.25 17.3352C11.25 17.5715 11.4187 17.8527 11.8687 17.7627C13.6554 17.1595 15.2079 16.0113 16.3077 14.4795C17.4076 12.9478 17.9995 11.1097 18 9.224C18 4.2515 13.9725 0.223999 9 0.223999Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_1275_1201">
            <rect
              width="18"
              height="18"
              fill="white"
              transform="translate(0 0.223999)"
            />
          </clipPath>
        </defs>
      </svg>
      <span>STAR PROJECT</span>
    </a>
  );
}

export default GithubButton;
