<svg
  width="28"
  height="54"
  viewBox="0 0 28 54"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M0.49987 26.9998L14.9998 0.500004L27.4999 26.9998L14.9998 54L0.49987 26.9998Z"
    fill="#EBFFFB"
  />
</svg>;
const Crystals = (props) => {
  return (
    <svg
      {...props}
      width="28"
      height="54"
      viewBox="0 0 28 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.49987 26.9998L14.9998 0.500004L27.4999 26.9998L14.9998 54L0.49987 26.9998Z"
        fill="#EBFFFB"
      />
    </svg>
  );
};

export default Crystals;
