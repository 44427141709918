const AppreciateBottomTopRight = (props) => {
  return (
    <svg
      {...props}
      width="362"
      height="361"
      viewBox="0 0 362 361"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7">
        <path
          opacity="0.7"
          d="M1 223.945L221.88 1.62842"
          stroke="#DEDEDE"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.3 0.3"
        />
        <path
          opacity="0.7"
          d="M2.77002 242.078L237.53 5.40625"
          stroke="#DEDEDE"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.3 0.3"
        />
        <path
          opacity="0.7"
          d="M9.85986 254.7L251.27 11.1602"
          stroke="#DEDEDE"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.3 0.3"
        />
        <path
          opacity="0.7"
          d="M19.8398 264.348L266.65 15.2285"
          stroke="#DEDEDE"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.3 0.3"
        />
        <path
          opacity="0.7"
          d="M27.6099 276.272L282.16 19.1514"
          stroke="#DEDEDE"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.3 0.3"
        />
        <path
          opacity="0.7"
          d="M36.75 286.782L298.62 22.0962"
          stroke="#DEDEDE"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.3 0.3"
        />
        <path
          opacity="0.7"
          d="M45.8198 297.369L311.22 29.022"
          stroke="#DEDEDE"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.3 0.3"
        />
        <path
          opacity="0.7"
          d="M54.8999 307.937L326.64 33.042"
          stroke="#DEDEDE"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.3 0.3"
        />
        <path
          opacity="0.7"
          d="M64.2598 318.214L339.18 40.0356"
          stroke="#DEDEDE"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.3 0.3"
        />
        <path
          opacity="0.7"
          d="M72.5298 329.635L349.62 49.1992"
          stroke="#DEDEDE"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.3 0.3"
        />
        <path
          opacity="0.7"
          d="M81.9102 339.893L361.4 56.9775"
          stroke="#DEDEDE"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.3 0.3"
        />
        <path
          opacity="0.7"
          d="M91.8599 349.56L373.01 64.9399"
          stroke="#DEDEDE"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.3 0.3"
        />
        <path
          opacity="0.7"
          d="M100.25 360.845L371.46 86.4927"
          stroke="#DEDEDE"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.3 0.3"
        />
      </g>
    </svg>
  );
};

export default AppreciateBottomTopRight;
