import React from "react";

const HeaderAccent = (props) => {
  return (
    <svg
      {...props}
      width="41"
      height="37"
      viewBox="0 0 41 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.7083 12.3797C3.69974 8.84672 4.19987 5.14783 4.65805 1.55966C4.75411 0.786824 4.21809 0.0692983 3.46292 0.0140958C2.7072 -0.0963094 2.01606 0.45604 1.92001 1.17367C1.47894 4.65144 1.00696 8.23928 0.0525103 11.6066C-0.1545 12.3243 0.271659 13.0974 1.00475 13.3182C1.73729 13.5391 2.50074 13.0973 2.7083 12.3797Z"
        fill="#008F75"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7643 23.0888C22.5667 17.4029 27.6221 10.9994 33.5801 5.42394C34.1366 4.92712 34.1664 4.04388 33.6469 3.49185C33.1269 2.93983 32.2525 2.88484 31.6961 3.43687C25.721 9.01233 20.6489 15.4158 14.8295 21.1017C14.2863 21.6537 14.2786 22.5367 14.8129 23.0888C15.3467 23.5856 16.2211 23.6408 16.7643 23.0888Z"
        fill="#008F75"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.0823 33.7432C35.7614 33.9088 32.4404 34.0741 29.1188 34.2397C28.3581 34.2397 27.7708 34.9025 27.8078 35.6753C27.8453 36.4482 28.4928 36.9999 29.2541 36.9999C32.5806 36.8343 35.9065 36.669 39.2325 36.5034C39.9932 36.4482 40.5767 35.7856 40.5353 35.0128C40.4939 34.2952 39.8425 33.688 39.0823 33.7432Z"
        fill="#008F75"
      />
    </svg>
  );
};

export default HeaderAccent;
