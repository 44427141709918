const clarityBlockLine = () => {
    return ( 
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_36_1104)">
                <path d="M20.9467 6.05994L12.2801 2.05994C12.1923 2.01933 12.0968 1.99829 12.0001 1.99829C11.9034 1.99829 11.8078 2.01933 11.7201 2.05994L3.05342 6.05994C2.9377 6.1135 2.83976 6.19911 2.77123 6.30664C2.70269 6.41417 2.66643 6.5391 2.66675 6.66661V17.9999C2.66643 18.1275 2.70269 18.2524 2.77123 18.3599C2.83976 18.4674 2.9377 18.5531 3.05342 18.6066L11.7201 22.6066C11.8078 22.6472 11.9034 22.6683 12.0001 22.6683C12.0968 22.6683 12.1923 22.6472 12.2801 22.6066L20.9467 18.6066C21.0625 18.5531 21.1604 18.4674 21.2289 18.3599C21.2975 18.2524 21.3337 18.1275 21.3334 17.9999V6.66661C21.3337 6.5391 21.2975 6.41417 21.2289 6.30664C21.1604 6.19911 21.0625 6.1135 20.9467 6.05994ZM12.0001 3.39994L19.0734 6.66661L12.0001 9.93327L4.92675 6.66661L12.0001 3.39994ZM4.00008 7.70661L11.3334 11.0933V20.9599L4.00008 17.5733V7.70661ZM12.6667 20.9599V11.0933L20.0001 7.70661V17.5733L12.6667 20.9599Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_36_1104">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
     );
}
 
export default clarityBlockLine;